import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";

import FooterContentFunc from "./footerContent";
import AnchorElement from "../../functions/AnchorElement.jsx";
import { Link } from "gatsby";
import styles from "./Styles/Footer.module.css";

function Footer() {
  const langState = useSelector(state => state.mainReducer.lang, shallowEqual);

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const [footerContent, setFooterContent] = useState({});

  useEffect(() => {
    setFooterContent(FooterContentFunc(langState, loginNameState));
    return () => {
      setFooterContent({});
    };
  }, [langState]);

  const finePrint = (
    <small>Copyright &copy; 2025 DFS Corporation. All Rights Reserved</small>
  );
  if (footerContent && Object.keys(footerContent).length > 0) {
    return (
      <>
        <footer id="footer">
          <div className="new-footer" style={{ maxWidth: "100%" }}>
            <div className="columns " style={{ maxWidth: "100%" }}>
              {/* <div className="footer-container"> */}
              <div className={styles.footerContainer}>
                <div className={styles.colWrapper}>
                  <span style={{ lineHeight: "24px" }}>
                    Support
                    <br />
                    800.490.6327
                  </span>

                  <dl>
                    <dt>Monday-Friday&nbsp; </dt>
                    <dt> 8:00 am - 5:30 pm ET</dt>
                  </dl>
                  <Link
                    className={styles.accessibilityPolicyLink}
                    to="/website-accessibility-policy"
                  >
                    Website Accessibility Policy
                  </Link>
                </div>

                <div className={styles.colWrapper}>
                  <span>Navigating DFSonline.ca</span>
                  <Link to="/products/">
                    <span>Browse Products</span>
                  </Link>

                  <ul className={styles.ul}>
                    <li>
                      <Link
                        className={styles.listLink}
                        to="/products/business-cheques/"
                      >
                        Business Cheques
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={styles.listLink}
                        to="/products/banking-products/"
                      >
                        Banking Products
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={styles.listLink}
                        to="/products/business-forms/"
                      >
                        Business Forms
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={styles.listLink}
                        to="/products/office-supplies/"
                      >
                        Office Supplies
                      </Link>
                    </li>
                  </ul>

                  <span>Explore DFS Dealer Resources</span>
                  <ul className={styles.ul}>
                    <li>
                      <Link
                        className={styles.listLink}
                        to="/sales-resources/promotions/"
                      >
                        Current Promotions
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={styles.listLink}
                        to="/sales-resources/sales-material/"
                      >
                        Sales Materials
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={styles.listLink}
                        to="/sales-resources/trade-shows-events/"
                      >
                        Trade Shows &amp; Events
                      </Link>
                    </li>
                  </ul>

                  <br />
                </div>

                <div className={styles.colWrapper}>
                  <span>Other DFS Services &amp; Sites</span>

                  <ul className={styles.list}>
                    <li>
                      <a
                        className={styles.listLink}
                        href="https://businesscfpca.ezshield.com/default.aspx"
                      >
                        Businesscfpca.ezshield.com
                      </a>
                    </li>
                  </ul>
                </div>

                <div className={styles.colWrapper}>
                  <span>DFS Company Information</span>

                  <ul>
                    <li>
                      <Link
                        className={styles.listLink}
                        to="/why-choose-dfs/dfs-policies/privacy-policy/"
                      >
                        Privacy / Security
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={styles.listLink}
                        to="/why-choose-dfs/dfs-policies/dealer-policies/"
                      >
                        DFS Dealer Terms &amp; Conditions
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={styles.listLink}
                        to="/tools-resources/customer-service-topics/"
                      >
                        Customer Service
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div id="finePrint" className={styles.finePrint}>
          {finePrint}
        </div>
      </>
    );
    // }
  } else {
    return null;
  }
}

export default Footer;
